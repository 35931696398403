import ExtensibleCustomError from "extensible-custom-error";
import type { RootState } from "../store";

/**
 * 未ログイン状態を表すエラー
 */
export class AppErrorNotLogin extends ExtensibleCustomError {}

/**
 * ログインセッションの有効期限切れを表すエラー
 */
export class AppErrorLoginSessionExpiration extends ExtensibleCustomError {}

/**
 * 検索結果が 0 件であることを表すエラー
 */
export class AppErrorNoSearchResult extends ExtensibleCustomError {}

/**
 * 検索結果の件数が上限超過であることを表すエラー
 */
export class AppErrorSearchLimitExceeded extends ExtensibleCustomError {}

/**
 * Fetch API Abort された事を表すエラー
 */
export class AppErrorFetchAborted extends ExtensibleCustomError {}

/**
 * オフライン状態になった際の疑似エラー
 */
export class AppOfflineAttention extends ExtensibleCustomError {}

/**
 * オフライン状態で作成された下書きが存在しないことを示すエラー
 */
export class AppErrorDraftNotFound extends ExtensibleCustomError {}

export interface ErrorValue {
  time?: string;
  title: string;
  url?: string;
  user?: string;
  version?: string;
  detail?: string;
}

export const ErrorCodeFileNotFound = "E404-3";

export async function newErrorValue(options: ErrorValue): Promise<ErrorValue> {
  const fallback = (f: () => string | undefined) => {
    let result;
    try {
      result = f();
    } catch (e) {
      result = String(e);
    }
    return result;
  };

  const time = fallback(() => new Date().toISOString());

  const url = fallback(() => window.location.href);

  let state: RootState | undefined;
  try {
    const { default: store } = await import("../store");
    state = store.getState();
  } catch {}

  const user = fallback(() => {
    const u = state?.account.user;
    if (u) return `${u.name} (ID: ${u.id})`;
  });

  const version = fallback(() => {
    const script = document.querySelector('script[src*="main"]');
    if (script) return script.getAttribute("src")!;
  });

  return { time, url, user, version, ...options };
}

export async function isInvalidLoginSessionError(x: unknown): Promise<boolean> {
  try {
    if (!(x instanceof Response)) return false;
    if (x.status !== 403) return false;
    const data = await x.json();
    if (
      !(
        typeof data === "object" &&
        data !== null &&
        Array.isArray(data.errors) &&
        data.errors.length > 0
      )
    )
      return false;
    return ["E403-2", "E403-3"].includes(data.errors[0].errorCode);
  } catch {}
  return false;
}

export function isExpectedError(x: unknown): boolean {
  if (
    x instanceof AppErrorNotLogin ||
    x instanceof AppOfflineAttention ||
    // このエラーは無視しても問題ないため、エラー表示から弾く
    (x instanceof ErrorEvent &&
      (x.message === "ResizeObserver loop limit exceeded" ||
        x.message ===
          "ResizeObserver loop completed with undelivered notifications."))
  )
    return true;
  return false;
}

export async function convertToError(x: unknown): Promise<ErrorValue> {
  if (x instanceof Response) {
    let body: string;
    try {
      body = await x.text();
    } catch (e) {
      body = `Failed to read response body: ${await convertToString(e)}`;
    }
    return await newErrorValue({
      title: `HTTP Response ${x.status}`,
      detail: `${x.url}\n${body}`,
    });
  }

  return await newErrorValue({
    title: "Unknown Error",
    detail: await convertToString(x),
  });
}

export async function convertToString(x: unknown): Promise<string> {
  if (typeof x === "object" && x !== null) {
    const obj: any = x;
    if (obj.message) return String(obj.message);
    if (obj.toString instanceof Function) return String(obj.toString());
  }
  return String(x);
}
