import { v4 } from "uuid";
import {
  ServiceReport as WebApiServiceReport,
  ServiceReportStatus,
  ServiceReportUsedParts,
  ServiceReportWorkingTime,
  ServiceReportWorker as WebApiServiceReportWorker,
  ServiceReportHeaderDetail as WebApiServiceReportHeaderDetail,
  ServiceReportHeaderCreate as WebApiServiceReportHeaderCreate,
  ServiceReportHeaderUpdate as WebApiServiceReportHeaderUpdate,
  ServiceReportWorkerUpdate as WebApiServiceReportWorkerUpdate,
} from "../webapi/generated";
import {
  SERVICE_REPORT_OFFLINE_STATUS,
  canTransmit,
  isWorkOrderType,
  serviceReportOrderType,
} from "./serviceReport";

/**
 * サービスレポート（一覧用）
 */
export type ServiceReport = Omit<WebApiServiceReport, "id" | "status"> & {
  id?: number;
  status: ServiceReportHeaderStatus;
  // 下書き所有判定
  hasDraft?: boolean;
  // 下書きの識別子
  serviceReportDraftKey?: string;
};

/**
 * サービスレポートヘッダ情報（詳細）
 */
export type ServiceReportHeaderDetail = Omit<
  WebApiServiceReportHeaderDetail,
  "id" | "status" | "models"
> & {
  id: string;
  hasDraft: boolean;
  status: ServiceReportHeaderStatus;
  models?: string;
};

/**
 * サービスレポートヘッダ情報のステータス
 */
export type ServiceReportHeaderStatus =
  | ServiceReportStatus
  | typeof SERVICE_REPORT_OFFLINE_STATUS;

export const serviceReportHeaderStatus = {
  OFFLINE: SERVICE_REPORT_OFFLINE_STATUS,
  IN_PROGRESS: ServiceReportStatus.NUMBER_1,
  COMPLETED: ServiceReportStatus.NUMBER_2,
  TO_BE_SUBMITTED: ServiceReportStatus.NUMBER_3,
  SUBMIT_COMPLETED: ServiceReportStatus.NUMBER_4,
  BILLED: ServiceReportStatus.NUMBER_5,
};
export const serviceReportHeaderStatusText = {
  [serviceReportHeaderStatus.OFFLINE]: "Offline",
  [serviceReportHeaderStatus.IN_PROGRESS]: "In Progress",
  [serviceReportHeaderStatus.COMPLETED]: "Completed",
  [serviceReportHeaderStatus.TO_BE_SUBMITTED]: "To be submitted",
  [serviceReportHeaderStatus.SUBMIT_COMPLETED]: "Submit-completed",
  [serviceReportHeaderStatus.BILLED]: "Billed",
};
export const serviceReportHeaderStatuses = Object.values(
  serviceReportHeaderStatus
);

/**
 * サービスレポートヘッダ情報に紐付く明細跨ぎの使用部品
 */
export type UsedParts = ServiceReportUsedParts & {
  serviceReportId: string;
};

/**
 * サービスレポートヘッダ情報に紐付く明細跨ぎの工数情報
 */
export type WorkingTimes = ServiceReportWorkingTime & {
  serviceReportId: string;
};

/**
 * サービスレポートヘッダ情報の下書き
 */
export type ServiceReportHeaderDraft = Omit<
  ServiceReportHeaderDetail,
  | "id"
  | "models"
  | "status"
  | "orderDetailUrl"
  | "customerRemarks"
  | "customerSignature"
  | "customerSignedAt"
  | "technicianSignature"
  | "technicianSignedAt"
> & {
  id?: number;
  key: string;
  models?: string; // カンマ区切りの文字列
};

/**
 * サービスレポートのヘッダ情報の作成用引数
 */
export type ServiceReportHeaderCreate = WebApiServiceReportHeaderCreate;

/**
 * サービスレポートのヘッダ情報の下書き作成用引数
 */
export type ServiceReportHeaderDraftCreate = WebApiServiceReportHeaderCreate & {
  key: string;
} & Partial<{
    shipFlagName: string;
    referenceNo: string;
    referenceNo2: string;
    receptionDate: string;
    models: string;
    serviceKind: string;
    serviceLocationName: string;
    managementUnitName: string;
    consigneeName: string;
  }> & { serviceTechnicianName: string };

/**
 * サービスレポートのヘッダ情報の更新用引数
 */
export type ServiceReportHeaderUpdate = WebApiServiceReportHeaderUpdate;

/**
 * サービスレポートの作業者
 */
export type ServiceReportWorker = WebApiServiceReportWorker;

/**
 * サービスレポートの作業者更新用引数
 */
export type ServiceReportWorkerUpdate = WebApiServiceReportWorkerUpdate;

/**
 * 下書き用の識別子の作成
 */
export function generateDraftKey() {
  return v4();
}

/**
 * オーダー種別に応じてサービスレポートのステータスを一覧で取得する
 *
 * @param orderType オーダー種別
 * @returns ステータス一覧
 */
export function getStatusesByOrderType(
  orderType: string = String(serviceReportOrderType.WORK_ORDER)
) {
  const statuses = [serviceReportHeaderStatus.IN_PROGRESS];
  if (isWorkOrderType(orderType)) {
    // ワークオーダー用のステータス
    if (canTransmit()) {
      statuses.push(
        serviceReportHeaderStatus.TO_BE_SUBMITTED,
        serviceReportHeaderStatus.SUBMIT_COMPLETED
      );
    } else {
      statuses.push(serviceReportHeaderStatus.COMPLETED);
    }
  } else {
    // サービスオーダー用のステータス
    statuses.push(serviceReportHeaderStatus.COMPLETED);
  }
  statuses.push(serviceReportHeaderStatus.BILLED);
  return statuses;
}

/**
 *
 * オーダー種別に応じてサービスレポートのステータスにフィルターをかける
 *
 * @param statuses ステータス一覧
 * @param orderType オーダー種別
 * @returns オーダー種別に応じたステータス一覧
 */
export function filterStatusesByOrderType(
  statuses: string[],
  orderType: string = String(serviceReportOrderType.WORK_ORDER)
) {
  const {
    COMPLETED,
    SUBMIT_COMPLETED,
    TO_BE_SUBMITTED,
  } = serviceReportHeaderStatus;

  if (isWorkOrderType(orderType) && canTransmit()) {
    return statuses.filter((s) => s !== String(COMPLETED));
  }
  return statuses.filter(
    (s) => s !== String(TO_BE_SUBMITTED) && s !== String(SUBMIT_COMPLETED)
  );
}

/**
 * データ連携の可否に応じてステータス一覧を置換した結果を返す
 *
 * @param statuses ステータス一覧
 * @param orderType オーダー種別
 * @returns オーダー種別に応じたステータス一覧
 */
export function replaceTransmitStatuses(
  statuses: string[],
  orderType: string = String(serviceReportOrderType.WORK_ORDER)
) {
  // データ連携が可能な場合は変換不要
  if (canTransmit()) return statuses;

  const {
    COMPLETED,
    TO_BE_SUBMITTED,
    SUBMIT_COMPLETED,
  } = serviceReportHeaderStatus;
  let parsedStatuses = statuses;

  // データ連携が不可な場合は変換が必要
  // オーダー種別にワークオーダーが選択されている場合は「作成済み」を「データ連携待ち」と「データ連携済み」に置き換える
  if (isWorkOrderType(orderType) && statuses.includes(String(COMPLETED))) {
    parsedStatuses = statuses.filter((s) => s !== String(COMPLETED));
    parsedStatuses.push(String(TO_BE_SUBMITTED), String(SUBMIT_COMPLETED));
  }
  return parsedStatuses;
}

/**
 * データ連携の可否に応じてステータス値を置換した結果を返す
 *
 * @param status ステータス値
 * @returns 変換済みのステータス値
 */
export function replaceTransmitStatus(status: ServiceReportStatus) {
  if (canTransmit()) return status;

  const {
    COMPLETED,
    TO_BE_SUBMITTED,
    SUBMIT_COMPLETED,
  } = serviceReportHeaderStatus;

  // 変換対象
  const targets = [TO_BE_SUBMITTED, SUBMIT_COMPLETED];
  return targets.includes(status) ? COMPLETED : status;
}
